import { LocalPage } from '@mytypes/local';
import { QUESTION_TYPE, SubmitCharacteristicsValuesRequestPayload } from '@mytypes/protocol';
import { completeStepApi, deleteCharacteristicValueApi, submitCharacteristicsValuesApi, submitsCharacteristicUploadApi } from '@services/protocol';
import { deleteFieldVisitWithDexie, getFieldVisitWithDexie } from '@services/local';
import { parseCharacterizationValueToSubmit } from './parseCharacterizationValueToSubmit';
import { restoreSavedValuesInLocalVisit } from './restoreSavedValuesInLocalVisit';

const hasQuestionChanged = (characterizationId: string, newValue: any, latitude: number | undefined, longitude: number | undefined, savedValues: any[]) => {
  const value = savedValues.find(savedValue => savedValue.characterization_id === characterizationId);
  return value.value !== newValue || (value.latitude !== latitude && value.longitude !== longitude);
};

type CharacteristicToUpdate = {
  characterization_id: string;
  values: (string | number | boolean)[];
  latitude: number | undefined;
  longitude: number | undefined;
};

export const submitLocalFieldVisit = async (localId: number, complete: boolean = true) => {
  const visit = await getFieldVisitWithDexie(localId);
  if (!visit) {
    return { success: false };
  }

  if (!visit.savedValues.length) {
    await restoreSavedValuesInLocalVisit(visit);
  }

  const characterizationsToUpdate: CharacteristicToUpdate[] = [];
  const characterizationsToDelete: any[] = [];

  visit.pages.forEach(({ questions }) => {
    questions.forEach(({ characterization_id, value, valueId, valueType, latitude, longitude }) => {
      if (valueType !== QUESTION_TYPE.UPLOAD && hasQuestionChanged(characterization_id, value, latitude, longitude, visit.savedValues)) {
        if (value) {
          characterizationsToUpdate.push({
            characterization_id,
            values: parseCharacterizationValueToSubmit(value, valueType),
            latitude,
            longitude
          });
        } else {
          valueId.forEach((vId: any) => {
            characterizationsToDelete.push({ characterization_id, vId });
          });
        }
      }
    });
  });

  const fieldDataPayload: SubmitCharacteristicsValuesRequestPayload = {
    characterizations: characterizationsToUpdate,
    complete: true,
    fieldId: visit.fieldId,
    protocolId: visit.protocolId,
    stepId: visit.stepId,
    trialId: visit.trialId
  };

  const uploads: any[] = [];
  visit.pages.forEach((page: LocalPage) => {
    page.questions
      .filter(({ value, valueType }) => value && valueType === QUESTION_TYPE.UPLOAD)
      .forEach(({ characterization_id, value }) => {
        // @ts-ignore
        value.forEach(({ image, latitude, longitude, valueId, remove }) => {
          if (image || (valueId && remove)) {
            uploads.push({
              characterizationId: characterization_id,
              fieldId: visit.fieldId,
              latitude,
              longitude,
              protocolId: visit.protocolId,
              remove,
              stepId: visit.stepId,
              trialId: visit.trialId,
              value: image,
              valueId
            });
          }
        });
      });
  });

  try {
    if (characterizationsToUpdate.length) {
      await submitCharacteristicsValuesApi(fieldDataPayload);
    }

    if (characterizationsToDelete.length) {
      const promises = characterizationsToDelete.map((item: any) => {
        if (item.characterization_id) {
          return new Promise(resolve =>
            resolve(
              deleteCharacteristicValueApi({
                characterizationId: item.characterization_id,
                fieldId: visit.fieldId,
                protocolId: visit.protocolId,
                stepId: visit.stepId,
                trialId: visit.trialId,
                valueId: item.vId
              })
            )
          );
        }
      });
      await Promise.all(promises);
    }

    const uploadsPromises = uploads.map((upload: any) => {
      if (upload.valueId && upload.remove) {
        return new Promise(resolve => resolve(deleteCharacteristicValueApi(upload)));
      }
      return new Promise(resolve => {
        resolve(submitsCharacteristicUploadApi(upload));
      });
    });

    await Promise.all(uploadsPromises);
    if (complete) {
      await completeStepApi({
        trialId: visit.trialId,
        stepId: visit.stepId,
        protocolId: visit.protocolId,
        fieldId: visit.fieldId,
        clientId: visit.clientId
      });
      await deleteFieldVisitWithDexie(visit.id);
    }
    return { success: true, visit };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};
